import { Component, input, output } from '@angular/core'
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { debounceTime, Subject, takeUntil } from 'rxjs'
import { OrderService } from 'src/app/services/order.service'
import { Address, MilitaryAddressForm } from 'src/app/types/shipping'

@Component({
	selector: 'gwc-military-address',
	standalone: true,
	imports: [
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatSelectModule
	],
	templateUrl: './military.address.component.html',
	styleUrl: './military.address.component.scss',
})

export class MilitaryAddressComponent {	
	protected onValidForm = output<Address>()
	protected militaryAddressForm = input.required<FormGroup<MilitaryAddressForm>>()
	protected readonly LOCATION_TYPES = [
		{ label: 'Army Post Office', value: 'APO' },
		{ label: 'Diplomatic Post Office', value: 'DPO' },
		{ label: 'Fleet Post Office', value: 'FPO' }
	]
	protected readonly BASE_LOCATIONS = [
		{ label: 'Pacific', value: 'pacific' },
		{ label: 'Europe', value: 'europe' },
		{ label: 'Middle East', value: 'middle_east' },
		{ label: 'Canada', value: 'canada' },
		{ label: 'Africa', value: 'africa' },
		{ label: 'Americas', value: 'americas' }
	]
	protected readonly BASE_LOCATION_MAP = {
		pacific: 'AP',
		europe: 'AE',
		middle_east: 'AE',
		canada: 'AE',
		africa: 'AE',
		americas: 'AA'
	}
	private $destroy: Subject<void> = new Subject()

	constructor(
		private orderService: OrderService
	) {}
	
	ngOnInit() {
		this.listenToSubmit()
		this.listenToFormValidity()
		this.emitFormValue()
	}

	ngOnDestroy() {
		this.$destroy.next()
		this.$destroy.complete()
	}

	private listenToSubmit(): void {
		this.orderService.submittedSubject
			.pipe(takeUntil(this.$destroy))
			.subscribe(() => this.militaryAddressForm().markAllAsTouched())
	}

	private listenToFormValidity(): void {
		this.militaryAddressForm().statusChanges
			.pipe(
				debounceTime(1000),
				takeUntil(this.$destroy)
			).subscribe((status) => {
				if (status === 'VALID') {
					this.emitFormValue()
				}
			})
	}

	private emitFormValue(): void {
		const formValue = this.militaryAddressForm().getRawValue()
		const response = {
			address_1: `${formValue.address} BOX ${formValue.box}`, 
			state: formValue.base_location && this.BASE_LOCATION_MAP[formValue.base_location],
			postal_code: formValue.postal_code,
			city: formValue.city,
			country: 'US'
		}
		this.onValidForm.emit(response)
	}
}
