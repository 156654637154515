import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { FunnelComponent } from './funnel.component'
import { OrderComponent } from './order/order.component'
import { AccountComponent } from './account/account.component'
import { ShippingComponent } from './shipping/shipping.component'
import { FunnelResolverService } from './funnel-resolver.service'
import { AccountGuard } from 'src/app/guards/account.guard'
import { ShippingResolverService } from 'src/app/resolvers/shipping.resolver'
import { CountriesResolverService } from 'src/app/resolvers/countries.resolver'
import { StatesResolverService } from 'src/app/resolvers/states.resolver'
import { PaymentComponent } from 'src/app/components/payment/payment.component'
import { environment } from 'src/environments/environment'
import { ShippingRequiredResolver } from 'src/app/resolvers/shipping-required.resolver'

const routes: Routes = [
  {
    path: '',
    component: FunnelComponent,
    resolve: {
      // products: FunnelResolverService,
      shipping: ShippingResolverService,
      countries: CountriesResolverService,
      states: StatesResolverService
    },
    children: [
      {
        path: 'step-1',
        component: OrderComponent,
        data: {
          title: 'Step 1 - Service Options',
          step: 0,
          next: '/step-2',
          headline: environment['step-1']?.main ? environment['step-1'].main : "Select Service Options",
          description: environment['step-1']?.secondary ? environment['step-1'].secondary : "The fast, convenient way to expedite your application."
        }
      },
      {
        path: 'step-2',
        component: AccountComponent,
        canActivate: [
          AccountGuard
        ],
        data: {
          title: 'Step 2 - Customer Info',
          step: 1,
          next: '/step-3',
          previous: '/step-1',
          headline: "Customer Info",
          description: environment['step-2']?.secondary ? environment['step-2'].secondary : 'After selecting your expediting option, proceed to use our intuitive platform to fill out and manage your application.'
        }
      },
      {
        path: 'step-3',
        component: ShippingComponent,
        data: {
          title: 'Step 3 - Shipping & Handling',
          step: 2,
          next: '/step-4',
          previous: '/step-2',
          headline: "Shipping & Handling",
          description: "Trusted, safe, and secure. Track your order status every step of the way."
        }
      },
      {
        path: 'step-4',
        component: PaymentComponent,
        resolve: {
          shippingValid: ShippingRequiredResolver
        },
        data: {
          title: 'Step 4 - Payment Method',
          step: 3,
          previous: '/step-3',
          headline: "Select Payment Method",
          description: "We utilize the highest standards for security and encryption to ensure that your information is safeguarded."
        }
      },
      {
        path: '**',
        redirectTo: '/'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class FunnelRoutingModule {}