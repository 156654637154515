/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, OnDestroy, ElementRef, NgZone, signal } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { OrderService } from 'src/app/services/order.service'
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms'
import { Subscription, merge } from 'rxjs'
import { environment } from 'src/environments/environment'
import { TagService } from 'src/app/services/tag.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { Router, RouterModule } from '@angular/router'
import { ShippingOptionsComponent } from '../../../components/shipping.options/shipping.options.component'
import { GoogleAddressComponent } from 'src/app/components/google.address/google.address.component'
import { Address, ShippingForm, ShippingOption } from 'src/app/types/shipping'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatButtonModule } from '@angular/material/button'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'
import { MatFormField } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MilitaryAddressComponent } from 'src/app/components/military.address/military.address.component'
import * as _ from 'lodash'
import { CommonService } from 'src/app/services/common.service'
import { MatSelectModule } from '@angular/material/select'
import { Location } from 'src/app/types/products'
import { LocationSelectComponent } from 'src/app/components/location.select/location.select.component'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'

@Component({
	selector: 'app-shipping',
	templateUrl: './shipping.component.html',
	styleUrls: ['./shipping.component.scss'],
	standalone: true,
	imports: [
		GoogleAddressComponent,
		MatButtonModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
		RouterModule,
		ShippingOptionsComponent,
		MatFormField,
		MatInputModule,
		MatCheckboxModule,
		MilitaryAddressComponent,
		MatSelectModule,
		LocationSelectComponent
	]
})

export class ShippingComponent implements OnInit, OnDestroy {
	@ViewChild('shippingForm') shippingForm: FormGroupDirective
	@ViewChild('shippingAddressInput') shippingAddressInput: ElementRef

	public mapURL: SafeResourceUrl
	public shipping: FormGroup<ShippingForm>
	public shipping_type: string = 'basic'
	public show_shipping: boolean = false
	public show_inbound: boolean = false
	public submitted: boolean = false
	public subscription: Subscription
	public shipping_address
	public shipping_options: ShippingOption[] = this.shippingService.shipping_options
	public fedex_logo: boolean = environment.fedex_logo
	public shipping_required: boolean
	public recalledAddress: string
	public loading_dynamic_options: boolean = true
	public dynamic_options: ShippingOption[]
	public product: FormGroup<TravelerProductForm>
	public domain: string = environment.source.domain
	public idp: boolean = false
	public idp_zip: FormControl<string>
	public ready_for_dynamic: boolean = false
	public idp_changes_subscription: Subscription
	public isIdpEnv: boolean = environment.source.domain === 'idp'
	public showShippingForm: boolean = false
	public states: Location[] = []

	constructor(
		private sanitizer: DomSanitizer,
		private orderService: OrderService,
		private shippingService: ShippingService,
		private tagService: TagService,
		private router: Router,
		private ngZone: NgZone,
		private fb: FormBuilder,
		private commonService: CommonService
	) {
		this.product = this.orderService.orderForm.controls.travelers.controls[0].controls.products.controls[0]
		this.shipping_options = this.shippingService.shipping_options.filter(item => item.list)
		this.shipping = this.shippingService.shippingForm
		this.showShippingForm = this.isIdpEnv && this.shipping.getRawValue().address?.address_1 ? true : false
		if (this.shipping.value.place_id) {
			const url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDrBuO_eyjOF8o1vZ2bdbOsc3xW6_ALheo&q=place_id:" + this.shipping.value.place_id
			this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(url)
		}

		if (this.orderService.user) {
			let filtered = _.filter(this.orderService.user.addresses, ['type', this.shipping_required ? 'shipping' : 'billing'])

			if (filtered.length > 0 && filtered[0].address_1) {
				this.shipping_address = filtered[0]
			}
		}
	}

	ngOnInit() {
		this.checkShipping()
		this.initIdpFunctions()
		this.tagService.utagPageView('travel:expedited travel:shipping')
		this.listenToSubmit()
	}

	ngOnDestroy() {
		if (this.subscription !== undefined) {
			this.subscription.unsubscribe()
		}

		if (this.idp_changes_subscription) {
			this.idp_changes_subscription.unsubscribe()
		}
	}

	private initIdpFunctions() {
		if (this.isIdpEnv) {
			this.setStaticValidators()
			this.loadStates()
		}
	}

	public onLocationsLoaded() {
		this.setCountryValidators(this.shipping.controls.address.controls.country.value)
	}

	private listenToSubmit() {
		this.subscription = this.orderService.submittedSubject
			.subscribe((status: boolean) => {
				this.submitted = true
				this.shippingForm.onSubmit(undefined)
				this.orderService.setFormValidity(this.shippingForm.form.valid 
					|| (this.shipping_address && this.shippingForm.form.controls.speed.valid && this.shippingForm.form.controls.inbound_speed.valid))

				if (!this.shippingForm.form.valid) {
					this.orderService.scrollToFirstError()
				}
			})
	}

	private checkShipping() {
		let travelers = this.orderService.orderForm.getRawValue().travelers

		if (travelers.length === 1 && travelers[0].products.length === 1 
			&& ['ca_passport', 'idp'].includes(travelers[0].products[0].type)) {
			const product_type = travelers[0].products[0].type

			if (product_type === 'ca_passport' && travelers[0].products[0].processing_type === 'regular') {
				// If there is only one product, it is Canadian passport, and customer is not in a rush we can offer shipping
				this.shipping_type = 'dynamic'
				this.shipping_required = true
				this.show_shipping = true
				this.shipping.controls.speed.setValidators([])
				this.shipping.controls.inbound_speed.setValidators([Validators.required])
	
				if (this.shipping.getRawValue().address?.address_1) {
					this.getDynamicShipping()
				}
			} else if (product_type === 'idp') {
				this.shipping_required = true
				this.shipping_type = 'dynamic'
				this.show_shipping = true
				this.idp = true

				const idp_traveler: FormGroup<TravelerForm> = this.orderService.orderForm.controls.travelers.controls[0]
				// IDP doesn't required inbound shipping
				this.shipping.controls.inbound_speed.setValidators([])
				const cart = localStorage.getItem('cart')
				const militaryValue = (cart && JSON.parse(cart).shipping?.is_military) || false
				this.shipping.addControl('is_military', this.fb.control(militaryValue))
				this.idp_zip = idp_traveler.controls.info.controls.dl_zip_code
				this.idp_zip.addValidators(Validators.required)
				this.idp_zip.updateValueAndValidity()
				this.getDynamicShipping()
				this.idp_changes_subscription = merge(
					this.idp_zip.valueChanges,
					this.shipping.controls.address.valueChanges
				).subscribe(() => {
					this.getDynamicShipping()
				})
			}
		} else {
			this.shipping_required = travelers.some((traveler) => this.shippingService.requireShippingAddress(traveler))

			if (this.shipping_required) {
				this.showShipping()
			} else {
				this.shipping.controls.inbound_speed.reset()
				this.shipping.controls.speed.reset()
			}
		}

		if (!this.shipping_required) {
			this.router.navigate(['/step-4'])
		} else {
			if (this.shipping_address && this.shipping_address.address_1) {
				this.initMap()
			}
		}
	}

	public initMap() {
		this.recalledAddress = `${this.shipping_address.address_1}, ${this.shipping_address.city}, ${this.shipping_address.state} ${this.shipping_address.postal_code}, ${this.shipping_address.country}`
		const geocoder: google.maps.Geocoder = new google.maps.Geocoder() 
		 
		geocoder.geocode({ address: this.recalledAddress }, (results) => {
			this.ngZone.run(() => {
				if (results[0]) {
					this.saveAddress(results[0])
				}
			})
		})
	}

	public saveAddress(place: google.maps.GeocoderResult) {
		this.shipping.controls.place_id.patchValue(place.place_id)
		const url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDrBuO_eyjOF8o1vZ2bdbOsc3xW6_ALheo&q=place_id:" + place.place_id
		this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(url)
		let address: any = this.shippingService.getPlaceFromGoogleObject(place)

		if (this.shipping_address && this.shipping_address.address_2) {
			address.address_2 = this.shipping_address.address_2
		}

		this.shipping.controls.address_line.patchValue(place.formatted_address, { emitEvent: false })
		this.shipping.controls.address.patchValue(address)
		this.getDynamicShipping()
	}

	public onBlur() {
		if (!this.shippingForm.value.address.address_1) {
			this.shippingForm.control.controls.address_line.setErrors({'selected': true})
		}
	}

	private showShipping() {
		let travelers = this.orderService.orderForm.getRawValue().travelers
		this.show_shipping = !travelers.some((traveler) => {
			return this.shippingService.checkShipping(traveler)
		})

		if (this.show_shipping) {
			this.show_inbound = this.shippingService.needsInboundShipping(travelers)

			if (this.show_inbound) {
				this.shipping.controls.inbound_speed.setValidators([Validators.required])
			}
		}
	}

	public setMapURL(event: {url: string}) {
		this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(event.url)
	}

	public addressUpdated() {
		if (this.shipping_type === 'dynamic') {
			if (this.isIdpEnv) {
				this.showShippingForm = true
				this.shipping.markAllAsTouched()
			}
			if (this.idp && !this.idp_zip.valid) return

			this.getDynamicShipping()
		}
	}

	public getDynamicShipping() {
		if (this.idp && !(this.idp_zip.valid && this.shipping.controls.address.valid)) {
			return 
		}

		this.loading_dynamic_options = true
		this.ready_for_dynamic = true  
				
		if (this.shipping_type === 'dynamic') {
			if (this.idp) {
				this.shipping.controls.is_military.disable()
	
				this.shippingService.getAAALocation(this.idp_zip.value)
					.subscribe({
						next: (response) => {
							if (response.address) {
								const to_address = this.shipping.getRawValue().address
								let couriers

								if (to_address.country === 'US') {
									couriers = {
										"fedex": ["fedex_2_day", "standard_overnight"],
										"usps": ["usps_priority_3_days", "usps_priority_overnight"]
									}
								} else {
									couriers = {
										"fedex": ["fedex_international_priority", "international_economy"],
										"usps": ["int_usps_priority", "int_usps_express"]
									}
								}

								const product_uuid = this.orderService.orderForm.controls.travelers.controls[0].controls.products.controls[0].controls.product_uuid.value

								this.shippingService.getDynamicShippingPrices(product_uuid, response.address, to_address, couriers, 'dynamic')
									.subscribe({
										next: (response) => {
											this.loading_dynamic_options = false
											this.shipping.controls.is_military.enable()
											this.dynamic_options = response.data
										},
										error: () => {
											//Handle another error
										}
									})
							}
						},
						error: () => {
							//Handle location error
						}
					})
			} else {
				const product_uuid: string = this.product.getRawValue().product_uuid
				this.shippingService.getDynamicShippingOptions(product_uuid, this.shipping.getRawValue().address)
					.subscribe({
						next: response => {
							this.loading_dynamic_options = false
							this.dynamic_options = response.data
						}
					})
			}
		}
	}

	// While submitting in-person the Shipping form is considered valid no matter its values.
	// If the address was entered, then it is kept. Shipping options are not.
	public onSubmitInPerson(): void {
		const travelers = this.orderService.orderForm.getRawValue().travelers
		const product_type = travelers[0].products[0].type

		if (product_type === 'ca_passport' && travelers[0].products[0].processing_type === 'regular') {
			this.shipping.controls.inbound_speed.clearValidators()
			this.shipping.controls.inbound_speed.updateValueAndValidity()
		}
		this.shippingService.clearShippingOtions()
		this.orderService.setFormValidity(true)
	}

	public handleMilitaryAddress(militaryAddress: Address): void {
		this.shipping.controls.address.patchValue(militaryAddress)
	}

	public onMilitaryChckbxChange({checked}): void {
		this.dynamic_options = null
		this.shipping.reset({is_military: checked})
		this.showShippingForm = false
		this.resetMap()

		if (checked) { 
			this.shippingService.addMilitaryAddressControl()
		} else {
			this.shipping.removeControl('military_address')
			this.shipping.controls.address_line.setValidators([Validators.required])
			this.shipping.controls.address_line.updateValueAndValidity()
		}
	}

	private loadStates() {
		this.commonService.getStates().subscribe({
			next: (data) => {
				this.states = data;
			}
		});
	}

	private setCountryValidators(selectedCountry: string) {
		const postalCodeValidators = selectedCountry === 'US' ? [Validators.required, Validators.pattern('^\\d{5}$')] : [Validators.required]
		this.shipping.controls.address.controls.postal_code.setValidators(postalCodeValidators)
		this.shipping.controls.address.controls.postal_code.updateValueAndValidity()
	}

	private setStaticValidators() {
		const addressCtrl = this.shipping.controls.address
		const addressPattern = /^[A-Za-z0-9\s\-\.,/#]*$/
		const namePattern = /^[A-Za-z\s-]*$/
		const addressValidatorsWithRequired = [Validators.required, Validators.pattern(addressPattern)]
		const addressValidatorsWithoutRequired = [Validators.pattern(addressPattern)]
		const nameValidators = [Validators.required, Validators.pattern(namePattern)]

		addressCtrl.controls.address_1.setValidators(addressValidatorsWithRequired)
		addressCtrl.controls.address_2.setValidators(addressValidatorsWithoutRequired)
		addressCtrl.controls.city.setValidators(nameValidators)
		addressCtrl.controls.state.setValidators(nameValidators)
		addressCtrl.controls.country.setValidators([Validators.required])

		addressCtrl.controls.address_1.updateValueAndValidity()
		addressCtrl.controls.address_2.updateValueAndValidity()
		addressCtrl.controls.city.updateValueAndValidity()
		addressCtrl.controls.state.updateValueAndValidity()
		addressCtrl.controls.country.updateValueAndValidity()
	}

	public onCountryChange(event: MatAutocompleteSelectedEvent) {
		this.shipping.controls.address.controls.state.reset()
		this.setCountryValidators(event.option.value)
	}

	private resetMap() {
		this.mapURL = null
		this.recalledAddress = null
	}

}
