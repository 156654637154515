<mat-form-field class="gw-chkout-field gw-chkout-field--full gw-chkout-location"
	[class.gw-chkout-field--border]="hasBorder"
	[appearance]="appearance"
	[hideRequiredMarker]="true">
	@if (type === 'country' && field.value) {
		<span class="gw-chkout-location__flag fi fi-{{ field.value | lowercase }}">
		</span>
	}
	<mat-label>{{ label }}</mat-label>
	<input matInput #filter
		class="gw-chkout-location__input"
		[formControl]="field"
		[matAutocomplete]="auto"
		(input)="filterItems(true)"
		(focus)="filterItems()">
	<mat-autocomplete requireSelection
		#auto="matAutocomplete"
		[displayWith]="displayFn.bind(this)"
		(optionSelected)="onSelect.emit($event)">
		@for (item of filteredList(); track item.value) {
			<mat-option [id]="item.value"
				[value]="item.value">
				@if (type === 'country') {
					<span class="fi fi-{{ item.value | lowercase }}">
					</span>
				}
				{{ item.label }}
			</mat-option>
		}
	</mat-autocomplete>
	<mat-error>
		@if (field.hasError('match')) {
			Destination can't match citizenship.
		} @else {
			Please select a valid {{ label.toLowerCase() }}.
		}
	</mat-error>
</mat-form-field>
