import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ShippingService } from 'src/app/services/shipping.service'
import { OrderService } from 'src/app/services/order.service'
import { ProductService } from 'src/app/services/product.service'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})

export class ShippingRequiredResolver {
	
	constructor(
		private shippingService: ShippingService,
		private orderService: OrderService,
		private productService: ProductService,
		private router: Router
	) {}

	async resolve() {
		this.orderService.getCart()
		const travelers = this.orderService.orderForm.getRawValue().travelers
	
		// Initialize products before checking shipping requirements
		for (const traveler of travelers) {
			const products = traveler.products || []
			for (const product of products) {
				const filterData = {
					type: product.type || environment.main_product?.type,
					country: product.country || environment.main_product?.country || 'US',
					citizenship: traveler.info.citizenship || environment.main_product?.citizenship || 'US',
					residence_country: traveler.info.residence_country || environment.main_product?.residence_country || 'US',
					residency: traveler.info.residency || ''
				}
				
				await firstValueFrom(this.productService.getProducts(filterData))
			}
		}
		
		const shippingRequired = travelers.some(traveler => 
			this.shippingService.requireShippingAddress(traveler)
		)
	
		if (!shippingRequired) {
			return true
		}
		
		const { isValid, fromShippingRoute } = this.orderService.getStoredShippingValidity()
	
		if (!isValid || !fromShippingRoute) {
			this.router.navigate(['/step-3'])
			return false
		}
		
		return true
	}
	
}
