import { Component, OnInit, Input, ViewChild, ElementRef, output, ViewChildren, QueryList, WritableSignal, signal } from '@angular/core'
import { CommonService } from 'src/app/services/common.service'
import { map } from 'rxjs/operators'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { Location } from 'src/app/types/products'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatOption } from '@angular/material/autocomplete'
import { LowerCasePipe } from '@angular/common'
import { MatInputModule } from '@angular/material/input'

@Component({
	selector: 'gw-chkout-location-select',
	templateUrl: './location.select.component.html',
	styleUrls: ['./location.select.component.scss'],
	standalone: true,
	imports: [
		LowerCasePipe,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule
	]
})

export class LocationSelectComponent implements OnInit {
	@ViewChild('filter') filterInput: ElementRef<HTMLInputElement>
	@ViewChildren(MatOption) matOptions!: QueryList<MatOption>
	@ViewChildren(MatOption, { read: ElementRef }) matOptionsElementRef!: QueryList<ElementRef>

	@Input({ required: true }) field: FormControl
	@Input() label: string
	@Input() type: string
	@Input() appearance: string = 'fill'
	@Input() filterUS: boolean = true
	@Input() hasBorder: boolean = true
	public onSelect = output<MatAutocompleteSelectedEvent>()
	private selectedOption: MatOption
	public list: WritableSignal<Location[]> = signal([])
	public filteredList: WritableSignal<Location[]> = signal([])
	public locationsLoaded = output<void>()

	constructor(
		private commonService: CommonService
	) {}

	ngOnInit(): void {
		this.getList()
	}

	private getList(): void {
		let request = this.commonService.getCountries()

		if (this.label === 'Country' && this.filterUS) {
			request = request.pipe(
				map(countries => {
					return countries.filter(country => country.value !== 'US')
				})
			)
		}

		if (this.type == 'state') {
			request = this.commonService.getStates()
		}

		request.subscribe(response => {
			this.list.set(response)
			this.filteredList.set(response)
			this.locationsLoaded.emit()
		})
	}

	public displayFn(value: string): string {
		let filtered = this.list().filter(item => item.value === value)

		return filtered[0]?.label || '' 
	}

	public filterItems(isInputEvent?: boolean): void {
		const locations = this.list()

		if (isInputEvent) {
			const filterValue = this.filterInput.nativeElement.value.toLowerCase()

			if (!filterValue || (this.field.value && filterValue === this.selectedOption?.viewValue.toLowerCase())) {
				this.filteredList.set(locations)
			} else {
				const startList = []
				const middleList = []
		
				locations.forEach(option => {
					if (option.label.toLowerCase().startsWith(filterValue)) {
						startList.push(option)
					} else if (option.label.toLowerCase().includes(filterValue)) {
						middleList.push(option)
					}
				})
		
				this.filteredList.set([...startList, ...middleList])
			}
		} else {
			this.filteredList.set(locations)
		}
	}
}
