import { Injectable } from "@angular/core"
import { ApiService } from './api.service'
import { Observable, Subject, from, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { catchError, map } from 'rxjs/operators'
import { FilterAttributeData, Product } from '../types/products'
import * as _ from 'lodash'
import { visa_types } from '../data/visa.types'
import { TravelerInfo } from '../types/traveler'
import { HttpClient } from '@angular/common/http'
import { CommonService } from './common.service'

@Injectable({
  providedIn: 'root'
})

export class ProductService extends ApiService {
  public clearProductSubject: Subject<{product: string}> = new Subject<{product: string}>()
  public clearOptionSubject: Subject<{option: string}> = new Subject<{option: string}>()
  public visa_products = {}
  public passport_products = {}
  public mailaways = ['passport-renewal', 'name-change']

  constructor(
    http: HttpClient,
    private commonService: CommonService
  ) {
    super(http)
  }

  public getProducts(data: FilterAttributeData): Observable<any> {
    let products = this.checkForProducts(data)
    
    if (products) {
      return from([products])
    }

    return this.postRequest('product/filterAttribute', { ...data, domain: environment.source.domain})
      .pipe(
        map((response) => {
          switch (data.type) {
            case 'passport': {
              this.savePassportProduct(response.data)
              break
            }
            default:
              this.saveVisaProduct(response.data, data)
              break
          }

          return response.data
        }),
        catchError((error) => {
          localStorage.removeItem('cartStatus')
          localStorage.removeItem('cart')

          // window.location.reload()
          return of(null)
        })
      )
  }

  private saveVisaProduct(products, data: FilterAttributeData) {
    if (!data.residency || data.type !== 'visa') {
      data.residency = 'default'
    }

    const country = data.country.toUpperCase()

    this.visa_products[country] = this.visa_products[country] || {}
    this.visa_products[country][data.citizenship] = this.visa_products[country][data.citizenship] || {}
    this.visa_products[country][data.citizenship][data.residence_country] = this.visa_products[country][data.citizenship][data.residence_country] || {}
    this.visa_products[country][data.citizenship][data.residence_country][data.residency] = this.visa_products[country][data.citizenship][data.residence_country][data.residency] || {}
    this.visa_products[country][data.citizenship][data.residence_country][data.residency][data.type] = products
  }

  private savePassportProduct(products) {
    if (environment.hasOwnProperty('passport_products')) {
      products = products.filter((product) => {
        return environment.passport_products.includes(product.subtype)
      })
    }

    this.passport_products['US'] = this.passport_products['US'] || {}
    this.passport_products['US']['passport'] = products
  }

  public checkForProducts(data: FilterAttributeData): any {
    let product

    switch (data.type) {
      case 'passport':
        product = _.get(this.passport_products, ['US', 'passport'], null)
        break
      default:
        product = _.get(this.visa_products, [data.country, data.citizenship, data.residence_country, data.residency || 'default', data.type], null)
        break
    }

    return product
  }

  // Product Details
  public getProductDetails(traveler: TravelerInfo, product: Product, detail: string, args: any = {}) {
    switch (product.type) {
      case 'passport':
        return this.getPassportProductDetails(traveler, product, detail, args)
      default:
        return this.getVisaProductDetails(traveler, product, detail, args)
    }
  }

  private getVisaProductDetails(traveler: TravelerInfo, product: Product, detail: string, args: any) {
    try {
      const residency_key = !traveler.residency || product.type !== 'visa' ? 'default' : traveler.residency
      const residence_country = traveler.residence_country || 'US'
      const products = this.visa_products[product.country][traveler.citizenship][residence_country][residency_key][product.type]
      let filtered = null
      let service = null
      let addon
  
      if (product.product_uuid) {
        let product_list = products

        if (product.type === 'visa') {
          product_list = products[product.subtype]
        }

        if (product_list) {
          filtered = product_list.filter((item) => {
            return item.uuid == product.product_uuid
          })[0]
        }
  
        if (!filtered) {
          this.clearProductSubject.next({product: product.product_uuid})
          return
        } else if (product.option_uuid) {
          service = filtered.services.filter((service) => {
            return service.uuid == product.option_uuid
          })[0]
  
          if (!service) {
            this.clearOptionSubject.next({option: product.option_uuid})
          }
        }
      }
  
      switch (detail) {
        case 'products':
          return products
        case 'product':
          return filtered || products[0]
        case 'addon_name':
          addon = this.findAddon(filtered.addons, args)
          return addon ? addon.label : ''
        case 'addon_price':
          addon = this.findAddon(filtered.addons, args)
          return addon ? addon.price/100 : ''
        case 'addons': 
          return filtered ? filtered.addons : []
        case 'types': 
          return Object.keys(products)
        case 'entries':
          return products[product.subtype]
        case 'label':
          if (product.type === 'visa') {
            return this.commonService.getLocation(product.country)
              .pipe(
                map((response) => {
                  return `${response.label} ${visa_types[product.subtype]}`
                })
              )
          } else {
            return of(filtered.label)
          }
        case 'services':
          if (product.type === 'visa') {
            if (!filtered) {
              return null
            }

            return filtered.services

            if (product.subtype.includes('evisa') || product.subtype.includes('eta')) {
              return filtered.services.filter(service => {
                return service.name == 'E-Visa'
              })
            } else {
              return filtered ? filtered.services.filter(service => {
                return service.name != 'E-Visa'
              }) : null
            }
          } else {
            return products[0].services
          }
        case 'service_name':
          return service?.name
        case 'service_cost':
          return service ? service?.default_price : 0
        case 'gov_fee':
          return service?.gov_fee
        case 'subtotal':
          let addons_cost = 0
  
          product.addons.forEach((addon) => {
            let addon_object = this.findAddon(filtered.addons, addon)
            addons_cost += addon_object ? addon_object.price/100 : 0
          })
          
          return service ? parseFloat(service.gov_fee) + parseFloat(service.default_price) + addons_cost : 0
        case 'is_mailaway':
          return false
        case 'electronic':
        case 'subtype': 
          return filtered?.[detail]
        default:
          return service?.[detail]
      }
    } catch (error) {
      this.clearProductSubject.next({product: product.product_uuid})
      return null
    }
  }

  private getPassportProductDetails(traveler: TravelerInfo, product: Product, detail: string, args: any) {
    let products = this.passport_products['US']?.passport

    if (!products) {
      return null
    }

    let uuid = product.product_uuid 

    let filtered = products.filter((item) => {
      if (uuid) {
        return item.uuid == uuid
      } else {
        return item.subtype == 'passport-renewal'
      }
    })[0]

    if (!filtered) {
      this.clearProductSubject.next({product: product.product_uuid})
      return ''
    } 

    let service = filtered.options.filter((option) => {
      return option.uuid == product.option_uuid
    })[0]

    let addon_object

    switch (detail) {
      case 'products':
        return products
      case 'product':
        return filtered 
      case 'services':
        return filtered.options
      case 'subtype':
      case 'addons':
        return filtered[detail]
      case 'label':
        return from([filtered[detail]])
      case 'gov_fee':
        return this.getPassportGovFee(filtered, product.addons, service || filtered.options[0])
      case 'service_name':
        return service ? service.friendly_name : ''
      case 'service_slug':
        return service ? service.slug : ''
      case 'service_cost':
        return service ? service.cost/100 : ''
      case 'shipping_included':
      case 'gov_fee_included':
      case 'outbound_shipping_required':
      case 'inbound_shipping_required':
        return service ? service[detail] : filtered.options[0]?.[detail] || null
      case 'is_mailaway':
        return service && service.mailaway
        return this.mailaways.includes(filtered.subtype) && service.mailaway
      case 'inbound_shipping':
        return service ? service.shipping : 0
      case 'mailaway_inbound':
        return service ? service.mailawayInboundShipping : 0
      case 'mailaway_outbound':
        return service ? service.mailawayOutboundShipping : 0
      case 'addon_name':
        addon_object = this.findAddon(filtered.addons, args)
        return addon_object ? addon_object.label : ''
      case 'addon_price':
        addon_object = this.findAddon(filtered.addons, args)
        return addon_object ? addon_object.price/100 : ''
      case 'subtotal':
        if (!service) return 0
        
        let service_cost = service ? service.cost/100 : 0
        let addons_cost = 0

        product.addons.forEach((addon) => {
          let addon_object = this.findAddon(filtered.addons, addon)
          addons_cost += addon_object ? addon_object.price/100 : 0
        })

        return service_cost + addons_cost
      case 'service_price_with_insurance_and_card': {
        if (!service) return 0
        
        let service_cost = service ? service.cost/100 : 0
        let addons_cost = 0
        // "Passport Protection" -> "passport-insurance"
        // "Passport Card" -> "passport-card"
        const allowed_services = ['passport-insurance', 'passport-card']
        product.addons.forEach((addon) => {
          const addon_object = this.findAddon(filtered.addons, addon)
          if (allowed_services.includes(addon_object.icon)) {
            addons_cost += addon_object ? addon_object.price/100 : 0
          }
        })

        return service_cost + addons_cost
      }
    }
  }

  private findAddon(addons: any[], addon_uuid: string) {
    let addon_object = addons.filter((item) => {
      return item.uuid == addon_uuid
    })[0]

    return addon_object
  }

  private getPassportGovFee(product, addons, service) {
    let passport_card = product.addons.filter((addon) => {
      return addon.label == 'Passport Card'
    })[0]

    let has_card: boolean = addons.includes(passport_card.uuid)

    if (service?.gov_fee_included) {
      return has_card ? service.gov_fee/100 + 30 : service.gov_fee/100
    }

    let gov_fee = has_card ? 220 : 190 
    
    if (product.subtype == 'child-passport') {
      gov_fee = has_card ? 175 : 160
    }
    
    // TODO: Add mailaway check
    return gov_fee + 21.36
  }

  public getGAItem(traveler, product) {
    return this.getProductDetails(traveler.info, product, 'label')
      .pipe(
        map(label => {
          return {
            'id': this.getProductDetails(traveler.info, product, 'service_name'),
            'name': label,
            'quantity': 1,
            'price': this.getProductDetails(traveler.info, product, 'subtotal'),
            'type': product.type 
          }    
        })
      )
  }
}
